import {
  useContext,
  useEffect,
  useState
} from 'react';

import { useStyles } from './DesktopHeader.style';

import { Container } from '@/components/Container';
import { ScrollContext } from '@/components/PartialWrapper';

export const LinksRow = (): React.ReactElement => {
  const { classes, cx } = useStyles();
  const [isActive, setIsActive] = useState(true);
  const scrollPosition = useContext(ScrollContext);

  useEffect(() => {
    if (isActive && scrollPosition.y > 130) {
      setIsActive(false);
    }
    else if (!isActive && scrollPosition.y <= 80) {
      setIsActive(true);
    }
  }, [scrollPosition]);

  const links = [
    {
      href: '/sale/todays-specials',
      title: 'Today\'s Deals'
    },
    {
      href: '/account/trackorder',
      title: 'Track My Order',
      isBold: true
    },
    {
      href: 'https://help.mydeal.com.au/hc/en-us',
      title: 'Help & Support'
    }
  ];

  return (
    <div className={cx(
      classes.linksRow,
      isActive && classes.linksRowActive
    )}>
      <Container className={classes.linksWrapper} size="lg">
        {links.map((link, i) => (
          <a
            className={cx(
              classes.linksRowLink,
              link.isBold && classes.linksRowBoldLink
            )}
            href={link.href}
            key={i}
          >
            {link.title}
          </a>
        ))}
      </Container>
    </div>
  );
};
