import { createStyles } from '@mantine/core';

export const useStyles = createStyles(theme => ({
  searchWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',

    [theme.fn.largerThan('md')]: {
      alignItems: 'stretch'
    }
  },

  inputWrapper: {
    display: 'flex',
    position: 'relative',
    flexGrow: 1
  },

  input: {
    padding: '0.7rem 1rem',
    border: '1px solid #dcdcdc',
    width: '100%',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',

    [theme.fn.largerThan('xs')]: {
      padding: '1rem'
    },

    [theme.fn.largerThan('sm')]: {
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0',
      padding: '1.25rem 1rem'
    },

    '&:hover': {
      borderColor: theme.colors.orange[5]
    }
  },

  inputFocused: {
    outline: 'none',
    borderColor: theme.colors.orange[5]
  },

  button: {
    alignSelf: 'stretch',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
    width: '4rem',
    border: 'none',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    backgroundColor: theme.colors.orange[5],

    '&:hover': {
      cursor: 'pointer'
    },

    [theme.fn.largerThan('md')]: {
      width: '6rem'
    },

    [theme.fn.largerThan('lg')]: {
      width: '8rem'
    }
  },

  clearButton: {
    position: 'absolute',
    top: '50%',
    right: '1rem',
    transform: 'translateY(-50%)',
    padding: 0,
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.colors.orange[5],
    cursor: 'pointer'
  },

  searchText: {
    display: 'none',
    color: 'white',

    [theme.fn.largerThan('lg')]: {
      display: 'block'
    }
  },

  dropdownWrapper: {
    [theme.fn.largerThan('sm')]: {
      position: 'relative'
    }
  },

  dropdown: {
    position: 'absolute',
    top: '4rem',
    left: '0',
    width: '100%',
    height: '100vh',
    backgroundColor: 'white',

    [theme.fn.largerThan('sm')]: {
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
      height: 'auto',
      top: '0'
    },

    [theme.fn.largerThan('md')]: {
      width: 'calc(100% - 6rem)'
    },

    [theme.fn.largerThan('lg')]: {
      width: 'calc(100% - 8rem)'
    }
  },

  autocompleteDropdown: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: 'calc(100% - 8rem)',
    backgroundColor: 'white',
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },

  recentlySearchedList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },

  recentlyViewedList: {
    display: 'flex',
    flexWrap: 'nowrap',
    margin: 0,
    padding: '1rem',
    listStyleType: 'none',
    gap: '1rem',
    overflow: 'auto',

    [theme.fn.largerThan('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, minmax(0, 1fr))'
    }
  },

  listTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fafafa',
    padding: '0.8rem',
    color: '#636366',
    fontWeight: 'bold',
    margin: 0
  },

  listLink: {
    display: 'inline-block',
    width: '100%',
    padding: '0.5rem 0.8rem',
    color: '#444444',
    textDecoration: 'none',

    '&:hover': {
      backgroundColor: '#fafafa'
    }
  },

  listLinkWithPadding: {
    paddingLeft: '1.4rem'
  },

  clearAllButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: '#444444',
    textDecoration: 'underline',
    cursor: 'pointer',

    '&:hover': {
      color: theme.colors.orange[5]
    }
  },

  recentlyViewedImage: {
    minWidth: '120px',
    width: '100%',
    borderRadius: '8px',
    border: '1px solid #dcdcdc',
    transition: 'box-shadow 150ms ease-in-out',

    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'
    },

    [theme.fn.largerThan('sm')]: {
      minWidth: 'auto'
    }
  },

  stringMatch: {
    fontWeight: 'bold',
    color: '#282828'
  }
}));
