import { useState } from 'react';

import { useStyles } from './DesktopHeader.style';
import { LinksRow } from './LinksRow';

import { type HeaderProps } from '../Header';
import { IconsRow } from '../IconsRow';

import { Container } from '@/components/Container';
import { IconBurger, IconChevronDown } from '@/components/Icons';
import { PartialWrapper } from '@/components/PartialWrapper';
import { MegaMenu } from '@/features/MegaMenu';
import { SearchBar } from '@/features/SearchBar';

export const DesktopHeader = ({
  cartCount,
  isLoggedIn,
  userInitials,
  firstName,
  megaMenuProps
}: HeaderProps): React.ReactElement => {
  const { classes, cx } = useStyles();
  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false);
  const [isMegaMenuLocked, setIsMegaMenuLocked] = useState(false);
  const [isUserDropDownOpen, setIsUserDropDownOpen] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const handleTriggerUserDropDown = (newState: boolean): void => {
    setIsMegaMenuOpen(false);
    setIsUserDropDownOpen(newState);
  };

  const handleTriggerMegaMenu = (newState: boolean): void => {
    if (isMegaMenuLocked) return;

    setIsUserDropDownOpen(false);
    setIsMegaMenuOpen(newState);

    setIsMegaMenuLocked(true);
    setTimeout(() => {
      setIsMegaMenuLocked(false);
    }, 400);
  };

  const handleSearchFocus = (isFocused: boolean): void => {
    setIsSearchFocused(isFocused);
  };

  return (
    <div className={classes.headerWrapper}>
      {isSearchFocused && <div className={classes.searchOverlay}></div>}
      <LinksRow />
      <div className={classes.headerContainer}>
        <Container className={classes.header} size="lg">
          <a href="/" className={classes.logoWrapper}>
            <img
              alt="Online Deals by MyDeal"
              className={classes.logo}
              src="/content/assets/images/mydeal_weblogo.svg"
              width="171"
              height="38"
            />
          </a>
          <button
            type="button"
            className={cx(
              classes.shopByCategory,
              isMegaMenuOpen && classes.shopByCategoryActive
            )}
            onClick={() => handleTriggerMegaMenu(true)}
          >
            <IconBurger className="burger" size={16} />
            <span>Shop By Category</span>
            <IconChevronDown size={12} />
          </button>
          <SearchBar
            className={classes.search}
            isFocused={isSearchFocused}
            handleFocus={handleSearchFocus}
          />
          <IconsRow
            isLoggedIn={isLoggedIn}
            userInitials={userInitials}
            firstName={firstName}
            isUserDropDownOpen={isUserDropDownOpen}
            handleTriggerUserDropDown={handleTriggerUserDropDown}
            cartCount={cartCount}
          />
        </Container>
      </div>
      <Container className={cx(classes.megaMenuContainer, isMegaMenuOpen && classes.megaMenuOpen)} size="lg">
        <MegaMenu
          {...megaMenuProps}
          forceDesktop={true}
          isMegaMenuOpen={isMegaMenuOpen}
          handleTriggerMegaMenu={handleTriggerMegaMenu}
        />
      </Container>
    </div>
  );
};

export const DesktopHeaderWrapped = (props: HeaderProps): React.ReactElement => (
  <PartialWrapper>
    <DesktopHeader {...props} />
  </PartialWrapper>
);
