import { createStyles } from '@mantine/core';

export const useStyles = createStyles(theme => ({
  headerWrapper: {
    position: 'relative',
    display: 'none',
    zIndex: 1,

    [theme.fn.largerThan('md')]: {
      display: 'block'
    }
  },

  searchOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.4)'
  },

  headerContainer: {
    position: 'relative',
    backgroundColor: 'white',
    borderBottom: '1px solid #dcdcdc'
  },

  megaMenuContainer: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    zIndex: -1,
    pointerEvents: 'none'
  },

  megaMenuOpen: {
    pointerEvents: 'auto'
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1.5rem',
    height: '80px',

    [theme.fn.largerThan('lg')]: {
      gap: '2rem'
    }
  },

  logoWrapper: {
    maxWidth: '11rem',
    padding: '1rem 0'
  },

  logo: {
    width: '100%'
  },

  shopByCategory: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '0.5rem',
    gridColumn: 'span 2 / span 2',
    color: '#444444',
    textDecoration: 'none',
    alignSelf: 'stretch',
    paddingRight: '3px',
    minWidth: '170px',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',

    '&:hover': {
      color: theme.colors.orange[5]
    },

    '.burger': {
      color: '#b1b1b1'
    },

    '&:hover .burger': {
      color: theme.colors.orange[5]
    }
  },

  shopByCategoryActive: {
    boxShadow: `inset 0 -5px 0 ${theme.colors.orange[5]}`
  },

  chevronDown: {
    height: '1rem'
  },

  search: {
    flexGrow: 1,
    padding: '0.5rem 0'
  },

  icon: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '0 0.6rem',
    color: '#b1b1b1',
    fill: '#b1b1b1',

    '&:hover': {
      color: theme.colors.orange[5],
      fill: theme.colors.orange[5]
    }
  },

  linksRow: {
    position: 'relative',
    maxHeight: 0,
    overflow: 'hidden',
    backgroundColor: theme.colors.orange[5],
    color: 'white',
    transition: 'max-height 200ms ease-in-out',
    zIndex: 2
  },

  linksRowActive: {
    maxHeight: '100px'
  },

  linksWrapper: {
    display: 'flex',
    gap: '2rem',
    justifyContent: 'flex-end',
    padding: '0.7rem 1rem'
  },

  linksRowLink: {
    color: 'white',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline'
    }
  },

  linksRowBoldLink: {
    fontWeight: 'bold'
  }
}));
